* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: #eeedef;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
  overflow: overlay;
}

body,
input,
button,
select,
textarea {
  font: 600 1rem;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica", Arial, sans-serif;
  color: #fafafa;
}

a {
  text-decoration: none;
  color: #fafafa;
}

h1,
h2,
h3,
p {
  margin: 0px;
}

ul,
li,
menu,
ol {
  margin: 0px;
  padding: 0 1rem 0 0;
  list-style: none;
}

input,
textarea,
button,
select,
a,
div {
  -webkit-tap-highlight-color: transparent;
}

button:focus {
  outline: none !important;
}

/* ////////////// */

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

.social-links {
  background-color: #222222;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 2.25rem;
  border-top: 3px solid #a32529;
}

.social-links div {
  padding-right: 1.45rem;
}

.hero-content {
  background-repeat: no-repeat;

  box-shadow: 0.3px 0.5px 0.7px rgb(158 158 158 / 36%),
    0.8px 1.6px 2px -0.8px rgb(158 158 158 / 36%),
    2.1px 4.1px 5.2px -1.7px rgb(158 158 158 / 36%),
    5px 10px 12.6px -2.5px rgb(158 158 158 / 36%);
  position: relative;
  z-index: 1;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 89.72%,
    #201d1e 100%
  );
  z-index: 2;
}

.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  left: 0;

  top: 0;
}

.logo img {
  width: 100%;
  height: 100%;
  /* filter: drop-shadow(0px 3px 2px #1e1d1e); */

  filter: drop-shadow(0.3px 0.5px 0.7px rgb(0 8 10 / 36%))
    drop-shadow(0.4px 0.8px 1px rgb(0 8 10 / 36%))
    drop-shadow(1px 2px 2.5px rgb(0 8 10 / 36%));
}

.main-nav {
  text-align: left;
  align-self: stretch;
  margin-right: 0px;
}

.main-nav menu {
  width: 50rem;
  padding: 0px;
}

.main-nav li {
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 1.5px;
  line-height: 24px;
  -webkit-font-smoothing: subpixel-antialiased;
  font-stretch: 100%;
  text-size-adjust: 100%;
  padding-right: 4rem;
}

.main-nav a {
  color: #fafafa;
  transition: 0.5s;
}

.main-nav a:hover {
  color: #a32529;
  transition: 0.5s;
}

.hero-text {
  width: 100%;
  z-index: 2;
}

.hero-text h1 {
  text-align: center;
  font-family: "Questrial";
  text-size-adjust: 100%;
  font-stretch: 100%;
  /* filter: drop-shadow(0px 4px 4px #1e1d1e); */
  filter: drop-shadow(0.3px 0.5px 0.7px rgb(3 14 18 / 36%))
    drop-shadow(0.4px 0.8px 1px rgb(3 14 18 / 36%))
    drop-shadow(1px 2px 2.5px rgb(3 14 18 / 36%));
}

.hero-text h2 {
  color: #ffffff;
  font-family: "Oxygen";
  font-stretch: 100%;
  text-align: center;
}

img.hero-image-full {
  display: none;
}

img.hero-image-1x {
  display: none;
}

.main-background-container {
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  margin-top: 2.25rem;
  width: 100%;
  height: 100%;
}

.main-background-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

.first-row-container {
  background-color: #222222;
  text-align: center;
}

.about-container-main {
  background: url("assets/plain_logo.svg"), #fafafa;
  text-align: left;
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 44vw 47vw;
  background-attachment: fixed;
}

.about-container {
  /* filter: drop-shadow(0px 0px 4px rgb(0 0 0 / 48%)); */

  filter: drop-shadow(0.3px 0.5px 0.7px rgb(148 146 150 / 36%))
    drop-shadow(0.4px 0.8px 1px rgb(148 146 150 / 36%))
    drop-shadow(1px 2px 2.5px rgb(148 146 150 / 36%));
}

.main-hero-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 10% 5% 10%;
}

.main-hero-content img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  border-radius: 3px;
  box-shadow: 0.3px 0.5px 0.7px rgb(122 122 122 / 18%),
    0.8px 1.6px 2px -0.8px rgb(122 122 122/ 18%),
    2.1px 4.1px 4.2px -1.7px rgb(122 122 122 / 18%);
}

.main-hero-content h2 {
  font-weight: 500;
  font-size: 18px;
  padding: 1% 0px 0.25% 0px;
  color: #1e1e1e;
  text-align: center;
}

.main-hero-content h2 span {
  font-weight: 700;
}

.main-hero-content h2.english-text {
  font-style: italic;
  font-size: 14px;
  padding: 0.5% 0px;
}

@media screen and (max-width: 690px) {
  .about-container-main {
    padding-top: 0px;
  }

  .main-hero-content h2 {
    font-size: 14px;
    text-align: left;
    padding: 1.25% 0px 1% 0px;
  }

  .main-hero-content h2.english-text {
    font-size: 12px;
    font-style: italic;
  }

  .main-hero-content {
    padding: 2.5% 5% 5% 5%;
  }
}

.aceves-h1 {
  color: #1e1e1e;
  text-align: center;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.aceves-h1 h1 {
  font-family: "Questrial";
  text-size-adjust: 100%;
  font-stretch: 100%;
  /* filter: drop-shadow(0px 0.5px 0.5px #1e1d1e); */
}

.about-container-text {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.875rem;
}

.about-container-text h2 {
  color: #1e1e1e;
  font-family: "Questrial";
  font-stretch: 100%;
  font-weight: 400;
  letter-spacing: normal;
  text-size-adjust: 100%;
}

.about-container-text h3 {
  color: #3e3e3e;
  font-family: "Questrial";
  font-stretch: 100%;
  font-weight: 400;
  letter-spacing: normal;
  text-size-adjust: 100%;
  margin-top: 0.75rem;
  margin-bottom: 1.9375rem;
}

.about-container-text p {
  color: #403d48;
  letter-spacing: normal;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  font-family: "Oxygen";
  text-size-adjust: 100%;
  font-stretch: 100%;
  margin: 0px;
}

.about-icon-list li {
  color: #403d48;
  font-family: "Oxygen";
  font-size: 18.5px;
  font-stretch: 100%;
  font-weight: 700;
  line-height: 30.6px;
  align-items: center;
  padding: 0.15625rem;
}

.capabilities-icon-list li {
  color: #403d48;
  font-family: "Oxygen";
  font-size: 18.5px;
  font-stretch: 100%;
  font-weight: 700;
  line-height: 30.6px;

  align-items: center;
  padding: 0.15625rem;
}

.capabilities-icon-list svg {
  margin-right: 1rem;
}

.about-icon-list svg {
  margin-right: 1rem;
}

.capabilities-container-main {
  background: linear-gradient(
    157.49deg,
    #eeedef -0.91%,
    rgba(238, 237, 239, 0.91) 45.59%,
    rgba(190, 194, 192, 0.69) 80.91%,
    rgba(190, 194, 192, 0.36) 121.06%
  );
  display: flex;
  justify-content: space-between;
}

.locations-container {
  /* box-shadow: 0 6px 20px rgb(0 0 0 / 30%); */

  box-shadow: 0.3px 0.5px 0.7px rgb(148 146 150 / 34%),
    1.5px 2.9px 3.7px -0.4px rgb(148 146 150 / 34%),
    2.7px 5.4px 6.8px -0.7px rgb(148 146 150 / 34%),
    4.5px 8.9px 11.2px -1.1px rgb(148 146 150 / 34%),
    7.1px 14.3px 18px -1.4px rgb(148 146 150 / 34%),
    11.2px 22.3px 28.1px -1.8px rgb(148 146 150 / 34%),
    17px 33.9px 42.7px -2.1px rgb(148 146 150 / 34%),
    25px 50px 62.9px -2.5px rgb(148 146 150 / 34%);
  margin-top: -28.5%;
  position: relative;
  border-radius: 6px;
}

.map-container {
  width: 100%;
  height: 100%;
  border: 0px;
}

.map-header {
  background-color: rgba(30, 30, 30);
  height: 58px;
  width: 100%;
  position: absolute;
  /* z-index: 3; */
  pointer-events: none;
  padding: 11px 0 11px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.map-header-section {
  display: flex;
  align-items: center;
}

.map-menu-icon {
  width: 24px;
  height: 18px;
  margin-right: 20px;
  /* background: url(//www.gstatic.com/gmeviewer/images/MyMaps_Icons003.png)
    no-repeat transparent;
  background-position: 0 0; */
  display: inline-block;
  vertical-align: middle;
  font-family: Roboto, Arial, sans-serif;
}

.map-title {
  font-size: 16px;
  font-weight: 500;
  vertical-align: middle;
  text-align: left;
  white-space: nowrap;
  line-height: 24px;
}

.map-share-icon {
  width: 21px;
  height: 21px;
  /* background: url(//www.gstatic.com/gmeviewer/images/viewer-icons001.png);
  background-position: 0 -23px; */
}

.fullscreen-map-icon {
  width: 21px;
  height: 21px;
  /* background: url(//www.gstatic.com/gmeviewer/images/viewer-icons001.png);
  background-position: -22px -23px; */
  margin-left: 40px;
  margin-right: 22px;
  margin-top: 1px;
}

.capabilities-container-right {
  padding-left: 3%;
}

.capabilities-container-text {
  /* width: 57ch; */
  display: flex;
  flex-direction: column;
  margin-bottom: 1.875rem;
}

.capabilities-container-text h2 {
  color: #1e1e1e;
  font-family: "Questrial";
  font-size: 3.75rem;
  font-stretch: 100%;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 4.125rem;
  text-size-adjust: 100%;
  z-index: 1;
}

.capabilities-container-text h3 {
  color: #3e3e3e;
  font-family: "Questrial";
  font-size: 2.8125rem;
  font-stretch: 100%;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 3.09375rem;
  text-size-adjust: 100%;
  margin-top: 0.75rem;
  margin-bottom: 1.9375rem;
  z-index: 1;
}

.capabilities-container-text p {
  color: #403d48;
  letter-spacing: normal;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  font-family: "Oxygen";
  text-size-adjust: 100%;
  font-stretch: 100%;
  margin: 0px;
  z-index: 1;
}

.services-container {
  filter: drop-shadow(0.3px 0.5px 0.7px rgb(20 20 20 / 36%))
    drop-shadow(0.8px 1.6px 2px rgb(20 20 20 / 36%))
    drop-shadow(2.1px 4.1px 5.2px rgb(20 20 20 / 36%));
}

.services-container-mask {
  background: url("./assets/Background_Pattern.png"),
    linear-gradient(323.06deg, #080c0c 0%, #333333 100%);
  clip-path: polygon(50% 5%, 100% 0, 100% 95%, 50% 100%, 0% 95%, 0 0);
  background-repeat: no-repeat;
  /* background-position: 122.5% -11%; */
  background-position: 101% -1.5%;
}

.services-main-container {
  width: 100%;
  background: linear-gradient(
    137.33deg,
    rgba(163, 37, 41, 0.5) -221.89%,
    rgba(0, 0, 0, 0) -111.85%,
    rgba(200, 45, 50, 0) 5.87%,
    rgba(116, 26, 29, 0.5) 100%
  );
}

.services-h1 {
  color: #fafafa;
}

.services-grid {
  grid-template-rows: 6.5vh 6.5vh;
  place-content: center;
}

.service-card {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-text {
  /* box-shadow: 0 12px 20px rgb(0 0 0 / 30%); */
  /* box-shadow: 0 1px 4px rgb(0 0 0 / 25%); */

  box-shadow: 0.3px 0.5px 0.7px rgb(21 10 10 / 36%),
    0.8px 1.6px 2px -0.8px rgb(21 10 10 / 36%),
    2.1px 4.1px 5.2px -1.7px rgb(21 10 10 / 36%),
    5px 10px 12.6px -2.5px rgb(21 10 10 / 36%);

  background: #fff;
  color: #403d48;
  font-family: "Oxygen";
  overflow: hidden;
  transition: all 200ms ease-in-out;
  border-radius: 6px;
  /* border: 1px solid green; */
}

.card-link-icon {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  /* border: 1px orangered solid; */
}

.card-text p {
  margin-top: 7px;
  color: #a32529;
  font-weight: 700;
  line-height: 21px;
  text-decoration-line: underline;
}

.card-text svg {
  /* margin-left: 80%; */
  margin-top: -6.5%;
}

.services-button-div {
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 5%;
}

.services-button {
  cursor: pointer;
  width: 9.3rem;
  height: 2.8rem;
  background-color: #1f1f1f;
  border: none;
  color: #fafafa;
  border-radius: 6.61px;
  font-weight: 300;
  font-family: "Helvetica";
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 1.8px;
}

.footer-shortcut svg {
  cursor: auto;
}

/* ######## */

.card-link-icon div {
  cursor: pointer;
}

.services-modal-overlay {
  display: none;
}

.card-text svg {
  cursor: pointer;
}

.patentes-container {
  background: url("./assets/Background_Pattern-2.png"),
    linear-gradient(8.53deg, #080c0c 0%, #333333 99.98%);
  background-repeat: no-repeat;
  background-position: -1% 1.5%;
}

.patentes-main-container {
  height: 100%;
  width: 100%;
  background: linear-gradient(
    185.27deg,
    rgba(163, 37, 41, 0.5) -221.89%,
    rgba(183, 42, 46, 0) -111.85%,
    rgba(200, 45, 50, 0) 5.87%,
    rgba(116, 26, 29, 0.5) 100%
  );
}

.patentes-h1 {
  color: #fafafa;
  /* padding-left: 10%; */
  font-size: 38px;
  letter-spacing: 0px;
  height: 32vh;
}

h1.desktop {
  display: none;
}

.patentes-grid {
  display: grid;
  grid-template-rows: 8.5vh 8.5vh;
  place-content: center;
}

.patente-card {
  background: #fff;
  /* box-shadow: 0 6px 20px rgb(0 0 0 / 30%); */
  box-shadow: 0.3px 0.5px 0.7px rgb(122 122 122 / 36%),
    0.8px 1.6px 2px -0.8px rgb(122 122 122/ 36%),
    2.1px 4.1px 5.2px -1.7px rgb(122 122 122 / 36%),
    5px 10px 12.6px -2.5px rgb(122 122 122 / 36%);
  transition: all 200ms ease-in-out;
  border-radius: 10px;
  cursor: pointer;
}

.patente-card:hover {
  transform: scale(1.085);
  transition: all 200ms ease-in-out;
  /* box-shadow: 0 14px 26px rgb(0 0 0 / 18%); */
  box-shadow: 0.3px 0.5px 0.7px rgb(71 71 71 / 34%),
    1.5px 2.9px 3.7px -0.4px rgb(71 71 71 / 34%),
    2.7px 5.4px 6.8px -0.7px rgb(71 71 71 / 34%),
    4.5px 8.9px 11.2px -1.1px rgb(71 71 71 / 34%),
    7.1px 14.3px 18px -1.4px rgb(71 71 71 / 34%),
    11.2px 22.3px 28.1px -1.8px rgb(71 71 71 / 34%);
  transition-delay: 200ms;
}

.patente-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.patente-card-header p {
  color: #a32529;
  font-size: 1.1rem;
  font-family: "Oxygen";
  font-weight: 600;
}

.patente-card svg {
  width: 100%;
}

.patente-card-content {
  display: flex;
}

.patente-card-content h2 {
  font-family: "Questrial";
  color: #403d48;
  font-weight: 600;
}

.patente-card-content h3 {
  color: #403d48;
  font-family: "Oxygen";
  font-weight: 500;
}

.contact-container {
  background: #fafafa;
  /* height: 150vh; */
}

.contact-main-card-container-section {
  display: flex;
  justify-content: center;
  /* padding-left: 1.8%; */
  /* border: 1px solid cyan; */
}

.contact-main-card-container {
  display: flex;
}

.contact-main-card {
  background: #fff;
  /* box-shadow: 0 6px 20px rgb(0 0 0 / 30%); */

  box-shadow: 0.3px 0.5px 0.7px rgb(158 158 158 / 36%),
    0.8px 1.6px 2px -0.8px rgb(158 158 158 / 36%),
    2.1px 4.1px 5.2px -1.7px rgb(158 158 158 / 36%),
    5px 10px 12.6px -2.5px rgb(158 158 158 / 36%);

  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.location-numbers-globe {
  display: flex;
  /* justify-content: space-between; */
}

.location-globe {
  width: 64px;
  height: 26px;
  border-radius: 10px;
  border: 2px solid #a32529;
  color: #a32529;
  font-family: "Oxygen";
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-align: center;
  transition: background-color 0.5s ease;
  cursor: pointer;
  margin-right: 2%;
}

.location-globe:hover {
  background: #a32529;
  color: #fafafa;
  transition: background-color 0.5s ease;
}

.location-globe-selected {
  background: #a32529;
  color: #fafafa;
  transition: background-color 0.5s ease;
  width: 64px;
  height: 26px;
  border-radius: 10px;
  border: 2px solid #a32529;
  font-family: "Oxygen";
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  margin-right: 2%;
  -webkit-tap-highlight-color: transparent;
}

.location-numbers {
  overflow: hidden;
}

.phonenumber {
  display: flex;
  align-items: center;
  margin-bottom: 6%;
}

.phonenumber svg {
  width: 28px;
  height: 28px;
  margin-top: 2px;
}

.phonenumber-digits {
  display: flex;
}

.phonenumber-digits p {
  color: #403d48;
  font-family: "Oxygen";
  font-weight: 700;
  font-size: 20px;
  line-height: 16px;
}

.numbers-transition-in {
  transition: opacity 850ms ease;
}

.numbers-transition-out {
  transition: 0s;
}

.numbers-transition-out-start {
  opacity: 0;
}

.numbers-transition-out-end {
  opacity: 0;
}

.numbers-transition-in-start {
  opacity: 0;
}

.numbers-transition-in-end {
  opacity: 1;
}

p.phone-color-style {
  color: #a32529;
}

.socialmedia-links {
  margin-bottom: 36px;
  display: flex;
  margin-top: 24.66px;
  display: block;
  /* border: 1px solid salmon; */
}

.socialmedia-links svg {
  height: 37px;
  opacity: 0.5;
}

.socialmedia-links svg:hover {
  opacity: 1;
  transition: opacity 0.5s ease;
}

.contact-main-card-title {
  display: flex;
  justify-content: space-between;
}

.input-box {
  padding: 0.16rem 0.2rem;
  margin: 0px;
  border-radius: 3px;
  border: 1.21px solid rgba(64, 61, 72, 0.2);
  background-color: rgb(64 61 72 / 2.5%);
  align-items: center;
  display: flex;
  width: 100%;
  height: 26px;
}

.input-box .input {
  background-color: transparent;
  border: none;
  outline: none;
  z-index: 1;
  height: 100%;
  font-family: "Oxygen";
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #403d48;
  width: 100%;
}

.name-boxes {
  display: flex;
  justify-content: space-between;
  margin-bottom: 11.33px;
}

.name-boxes p {
  font-family: "Oxygen";
  font-weight: 700;
  /* font-size: 13.5px; */
  font-size: 11.5px;
  /* line-height: 23px;
  line-height: 21px;*/
  line-height: 18.6px;
  color: #403d48;
}

.input.text-area {
  width: 100%;
  height: 100%;
  line-height: 14px;
}

.input-box.textarea-box {
  height: 66px;
}

textarea {
  resize: none;
}

.field.first-box {
  margin-right: 9px;
}

h2.swal2-title {
  font-family: "Questrial";
  font-size: 37.5px;
  color: #a32529;
  line-height: 38px;
  font-weight: 400;
}

div.swal2-html-container {
  color: #1e1e1e;
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  font-family: "Oxygen";
}

button.swal2-confirm {
  width: 9.3rem;
  height: 2.8rem;
  font-weight: 500 !important;
  font-family: "Helvetica" !important;
  line-height: 12px !important;
  letter-spacing: 1.8px !important;
  background-color: #1f1f1f !important;
  border-radius: 5.4px !important;
  border: none !important;
  color: #fafafa !important;
  font-size: 14px !important;
  box-shadow: none !important;
}

.footer-section {
  /* height: 40vh; */
  border-top: 2px solid rgb(122 122 122 / 60%);
  background-color: #222222;
  overflow: hidden;
  padding: 0;
}

.footer-columns {
  display: flex;
  /* padding: 4.75% 9% 3% 9%; */
  padding: 8.75% 9% 6% 9%;
  /* border: 1px solid green; */
}

.footer-columns li {
  font-family: "Oxygen";
  font-weight: 600;
  font-size: 19.5px;
  letter-spacing: 1.5px;
  line-height: 24px;
  font-stretch: 100%;
  text-size-adjust: 100%;
  padding-right: 4rem;
  margin-bottom: 16px;
  transition: 0.5s;
}

.footer-columns li a:hover {
  color: #a32529;
  transition: 0.5s;
}

.footer-column {
  margin-right: 8.25%;
}

.footer-column.first {
  margin-right: 2.85%;
}

.footer-last-column {
  /* margin-left: 8%; */
  display: flex;
  flex-direction: column;
  margin-right: 8.25%;
}

.footer-logo {
  width: 21.5rem;
  height: 5.5rem;
  /* border: 1px solid red; */
}

.footer-logo img {
  width: 100%;
  height: 100%;
  margin-bottom: 3rem;
  margin-left: -3.5rem;
}

.footer-logo-links li {
  font-size: 12px;
  margin-bottom: 1px;
}

.footer-logo-shortcuts {
  display: flex;
  justify-content: flex-end;
  /* width: 21rem; */
}

.footer-shortcut {
  margin-left: 2.25rem;
  transition: 0.5s ease;
}

.footer-shortcut svg:hover {
  fill: #a32529;
  transition: 0.5s ease;
}

.footer-bottom {
  display: flex;
  flex-direction: column;
}

.footer-bottom-line {
  display: flex;
  justify-content: center;
  /* width: 100%; */
}

.footer-bottom-line svg {
  width: 88.5%;
  height: 1.4px;
  opacity: 0.75;
}

.copyright-message {
  align-self: flex-end;
  padding: 4.25% 8% 4.25% 0px;
}

.copyright-message p {
  font-family: "Questrial";
  font-size: 14px;
}

[x-cloak] {
  display: none !important;
}

/* ////SMARTPHONE////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

@media screen and (min-width: 200px) {
  ::-webkit-scrollbar-thumb {
    background: transparent;
    display: none;
    width: 0px;
  }

  ::-webkit-scrollbar {
    background: transparent;
    display: none;
    width: 0px;
  }

  ::-webkit-scrollbar:hover {
    background: transparent;
    display: none;
    width: 0px;
  }

  ::-webkit-scrollbar:hover:active {
    background: transparent;
    width: 0px;
    display: none;
  }

  .main-header {
    height: 6.5rem;
  }

  .logo {
    height: 10rem;
    width: 14rem;
  }

  .logo img {
    /* margin-top: -8%; */
    margin-top: 2.5%;
  }

  .social-links {
    padding-right: 0px;
    padding-top: 5px;
  }

  .hero-content {
    background-size: cover;
    background-position: 79% center;
    /* height: 62vh; */
    overflow: hidden;
    display: flex;
    align-items: center;
    position: relative;
    flex-direction: column;
    justify-content: center;
  }

  img.hero-image-1x {
    display: block;
  }

  .hero-text {
    padding: 1% 7% 0px 7%;
  }

  .main-nav {
    overflow-x: hidden;
  }

  .main-nav menu {
    display: none;
  }

  svg.mobile-nav-bars {
    width: 30px;
    /* position: absolute; */
    top: 88px;
    right: 30px;
    cursor: pointer;
    z-index: 4;
    position: fixed;
  }

  svg.mobile-nav-bars.mobile-active {
    position: fixed;
  }

  svg.mobile-scrolled {
    top: 30px;
    right: 30px;
  }

  .mobile-nav-circle {
    cursor: pointer;
    width: 44px;
    height: 44px;
    top: 18px;
    right: 23px;
    box-shadow: 6px 7px 5px rgb(0 0 0 / 20%);
    border-radius: 50%; /* 50% of the width and h eight */
    background: linear-gradient(
      330deg,
      rgba(255, 255, 255, 0.35) -0.82%,
      rgba(255, 255, 255, 0.35) -0.82%,
      rgba(255, 255, 255, 0.65) -0.81%,
      rgba(255, 255, 255, 0.85) 26.22%,
      #ffffff 47.78%,
      #ffffff 86.94%
    );
    opacity: 0.8;
    position: fixed;
    z-index: 3;
    /* box-shadow: 0px 19px 26.1329px rgba(0, 0, 0, 0.2); */
  }

  .mobile-nav-menu {
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    right: 0;
    z-index: 4;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.35s;
  }

  .mobile-nav-menu-active {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .mobile-nav-menu-inactive {
    opacity: 1;
    width: 100%;
    height: 100vh;
  }

  .mobile-nav-menu ul {
    padding: 0px;
  }

  .mobile-nav-menu ul li {
    list-style: none;
    margin: 18px;
    text-align: center;
    padding: 0px;
    opacity: 0;
    transform: translateY(30px);
  }

  .mobile-nav-menu-inactive ul li {
    opacity: 1;
    transform: translateY(0);
    transition: transform 0.5s, opacity 0.5s;
    transition-delay: 0.5s;
  }

  .mobile-nav-menu ul li a {
    display: inline-block;
    text-decoration: none;
    color: white;
    font-size: 36px;
    font-weight: 600;
    position: relative;
    font-family: "Oxygen";
  }

  .hero-text {
    padding: 1% 2.5% 0px 2.5%;
  }

  .hero-text h1 {
    font-size: 36px;
    letter-spacing: -1px;
    line-height: 44.85px;
    font-weight: 400;
    margin-bottom: 4%;
  }

  .hero-text h2 {
    font-size: 14px;
    padding: 0px 3% 0px 3%;
    font-weight: 400;
    letter-spacing: 0.25px;
    line-height: 21px;
    margin-bottom: 15%;
  }

  /* .aceves-h1 {
    margin-right: 5.5%;
  } */

  .aceves-h1 h1 {
    font-size: 2.6rem;
    /* font-size: 36.5px; */
    letter-spacing: 0px;
    line-height: 46px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  svg.aceves-title-line {
    width: 228px;
  }

  .about-container-main {
    /* padding-top: 15%; */
    padding-top: 1.5%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 75%;
    clip-path: polygon(
      50% 0px,
      100% 0px,
      100% 86.09%,
      100% 86.09%,
      0px 92.09%,
      0px 0px
    );
    /* height: 192vh; */
  }

  .about-container-text h2 {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  .about-container-text h3 {
    font-size: 1.6rem;
    line-height: 1.8rem;
  }

  .locations-container {
    height: 370px;
    width: 100%;
    border: 10px solid #fafafa;
  }

  .capabilities-container-main {
    padding: 1% 1.5%;
    flex-direction: column;
    margin-top: -35%;
    padding-bottom: 31%;
  }

  .capabilities-container-right {
    margin-top: 26%;
  }

  .services-container {
    margin-top: -14%;
  }

  .services-container-mask {
    background-position: 40% -15%;
  }
  .mobile-services-overlay-effect {
    background: rgb(0 0 0 / 80%);
    position: fixed;
    width: 100%;
    height: 100vh;
    margin-top: 46%;
    z-index: 6;
  }

  .modal-mobile-services-container {
    margin-top: -46%;
    position: fixed;
    height: 100vh;
    right: 0;
    width: 100%;
    top: 0;
    z-index: 6;
  }

  .mobile-modal-services-grid-container {
    margin-top: -28%;
    height: 100vh;
  }

  .services-main-container {
    position: relative;
    /* height: 105vh; */
    /* overflow-x: auto;
    overflow-y: hidden; */
    padding-bottom: 56.5%;
    padding-top: 35%;
  }

  .services-grid-container {
    overflow-x: auto;
    overflow-y: hidden;
  }

  .services-h1 {
    left: 0;
    right: 0;
    margin-top: 10%;
    margin-bottom: 15%;
  }

  .services-grid {
    display: inline-flex;
    padding-left: 12.5px;
    inset: 0;
  }
  .mobile-modal-services-grid {
    padding-left: 0px;
  }

  .service-card {
    padding: 0px 0.3% 0px 0.3%;
    transition: opacity 625ms, visibility 625ms;
    height: 50%;
    width: 87.75vw;
  }

  .service-card img {
    width: 305.5px;
    height: 229px;
    object-fit: cover;
    border-radius: 1.5px;
    cursor: pointer;
  }

  .card-text {
    width: 86%;
    padding: 20.6px 14.23px 22.8px 14.23px;
    margin-top: -13%;
    /* height: 175px; 
    font-size: 19px;
    /* line-height: 21px; */
  }
  .card-text h1 {
    cursor: pointer;
    max-height: 45%;
    font-size: 1.17em;
    text-transform: none;
    text-align: left;
  }

  .card-text p {
    font-size: 18px;
  }

  .card-link-icon {
    margin-top: 14%;
  }

  .services-button-div {
    display: none;
  }

  .modal-mobile {
    opacity: 0;
    transform: translateY(30px);

    padding: 0px;
    margin: 0.5%;

    /* min-height: 550px; */
  }

  .modal-mobile-active {
    transition: transform 1s, opacity 0.5s;
    transition-delay: 0.25s;
    opacity: 1;
    transform: translateY(0px);

    z-index: 7;
    border: 2px solid white;
    border-radius: 23px;
    width: 100vw;
    background: white;
    position: relative;
  }

  .modal-mobile img {
    width: 100%;
    height: 100%;
  }

  .mobile-modal-picture-filter {
    background: linear-gradient(
      318deg,
      rgba(8, 12, 12, 0.32) -7.64%,
      rgba(7, 10, 34, 0.42) 30.19%,
      rgba(3, 5, 17, 0.52) 72.21%
    );
    background: linear-gradient(
      318deg,
      rgba(8, 12, 12, 0.32) -7.64%,
      rgba(7, 10, 34, 0.42) 30.19%,
      rgba(3, 5, 17, 0.52) 72.21%
    );
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .mobile-modal-picture-container {
    border: 4px solid white;
    border-radius: 21.5px;
    overflow: hidden;
    position: relative;
  }

  .modal-mobile-text-inactive {
    /* opacity: 0; */
    display: none;
  }

  .modal-mobile-text {
    padding: 12.6px 14.23px 13.6px 14.23px;

    background: linear-gradient(white, white) padding-box,
      linear-gradient(
          to right,
          rgba(163, 37, 41, 0.95) 0%,
          rgba(217, 89, 93, 0.8) 32.29%,
          rgba(217, 89, 93, 0.8) 68.23%,
          rgba(163, 37, 41, 0.95) 100%
        )
        border-box;
    border-radius: 0.8em;
    border: 2px solid transparent;
    margin-top: -7%;
    box-shadow: 0px 2px 1.8px rgba(64, 61, 72, 0.45);
    opacity: 1;
    display: block;
    z-index: 4;
  }

  .modal-mobile-img-active {
    /* opacity: 1; */
    display: block;
  }

  .modal-mobile-text h1 {
    text-align: center;
    text-transform: uppercase;
    font-size: 0.65em;
  }

  .modal-mobile-link {
    display: none;
  }

  .modal-mobile-text-content {
    font-family: "Oxygen";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: justify;
    color: #403d48;
    padding: 5% 1.75% 4% 1.75%;
  }

  .modal-mobile-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modal-mobile-links button {
    background: #a32529;
    border-radius: 14px;
    border: transparent 0px;
    padding: 8.25% 19.5%;
    padding-top: 8.5%;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.6px;
    cursor: pointer;
    color: #ffffff;
    font-family: "Arimo";
  }

  .footer-shortcut.modal {
    margin-left: 0.8rem;
    width: 1.8rem;
    height: 1.8rem rem;
  }

  .modal-mobile-container {
    width: 100%;
  }

  .hide-nav-modal {
    display: none;
  }

  .mobile-modal-icons {
    position: absolute;
    width: 336.5px;
    /* padding: 12px 14px 0 16px; */
    padding: 15px 14px 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    /* border: 1px solid red; */
  }

  .mobile-modal-icons svg:nth-of-type(1) {
    width: 13px;
    height: 19.75px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.65));
    z-index: 9;
  }

  .mobile-modal-icons svg:nth-of-type(2) {
    width: 24px;
    height: 23px;
    padding-right: 1px;
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.6)); */
  }

  .modal-mobile-services-container-mask {
    clip-path: none;
  }

  /* .carousel-button svg {
    width: 40px;
    height: 40px;
    filter: drop-shadow(0px 4px 4px #1e1d1e);
  } */
  button.carousel-button {
    color: white;
    margin: 0px;
    transition: all 500ms ease 0ms;
    border: none;
    z-index: 3;
    background-color: transparent;
    position: absolute;
    /* margin-top: 20%; */
    margin-top: 28.5%;
    /* cursor: pointer; */
  }

  button.prev {
    left: 0;
    padding-left: 2.25%;
  }

  button.next {
    right: 0;
    padding-right: 2.25%;
  }

  .patentes-container {
    /* background-position: 40% -20%; */
    background-position: 70% -9.5%;
    margin-top: -13%;
  }

  .patentes-main-container {
    padding-top: 20%;
    padding-bottom: 20%;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .patentes-h1 {
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .patentes-grid {
    display: flex;
    /* width: 677.8px; */
    flex-direction: column;
    align-items: center;
  }

  .patente-card {
    width: 300px;
    margin: 0px 1.5%;
    padding: 0 4%;
    margin-bottom: 5.5%;
    padding-bottom: 1.5%;
  }

  .patente-card-header {
    /* padding:0; */
    margin-bottom: -17px;
  }

  .patente-card-header img {
    height: 100px;
    width: 43%;
  }

  .patente-card-header p {
    font-size: 17px;
  }

  .patente-card-content {
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 3% 0;
  }

  .patente-card-content h2 {
    font-size: 31px;
  }

  .patente-card-content h3 {
    font-size: 20px;
  }

  .contact-container-main {
    padding-top: 25.5%;
    padding-bottom: 25.5%;
    /* margin-top: -38%; */
    /* margin-bottom: -15%; */
  }

  .contact-h1 {
    display: none;
  }

  .social-links-card {
    display: none;
    border-radius: 10px;
    height: 75%;
  }

  .contact-main-card {
    width: 90vw;
    overflow: hidden;
  }

  .contact-main-card-content-current {
    padding: 20px;
    padding-top: 0;
  }

  .contact-main-card-title {
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 37px;
  }

  .contact-main-card-title svg {
    margin-bottom: 20px;
  }

  .contact-main-card-title h2 {
    text-align: center;
    color: #403d48;
    font-family: "Questrial";
    font-size: 20px;
    line-height: 18px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .contact-main-card-mobile-header {
    width: 100%;
    display: flex;
    margin-bottom: 54px;
    overflow: hidden;
    position: relative;
    /* height: 100%; */
  }

  .tab-changed:after {
    content: "";
    position: absolute;
    top: 0;
    height: 8px;
    background: rgb(99 12 12);
    width: 10px;
    right: calc(50% - 9px);
  }

  .tab-initial::after {
    content: "";
    position: absolute;
    height: 8px;
    background: rgb(110 16 17);
    width: 10px;
    top: 0;
    right: 50%;
  }

  .mobile-header-tab {
    width: 50%;
    padding: 4.5% 10%;
    height: 100%;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .mobile-header-tab.phones {
    border-radius: 10px 0px 8px 0;
  }

  .mobile-header-tab.form {
    border-radius: 8px 10px 0 0;
  }

  .tab-unselected.phones {
    background: linear-gradient(
      165deg,
      rgba(120, 20, 21, 0.81) 2.35%,
      rgba(120, 20, 21) 34.9%,
      rgba(120, 20, 21) 69.49%,
      rgba(120, 20, 21) 100%
    );
  }

  .tab-unselected.form {
    background: linear-gradient(
      265deg,
      rgba(120, 20, 21, 0.81) 2.35%,
      rgba(120, 20, 21) 34.9%,
      rgba(120, 20, 21) 69.49%,
      rgba(120, 20, 21) 100%
    );
  }

  .tab-unselected {
    box-shadow: inset -0.3px -0.5px 0.9px 0px rgb(70, 1, 1, 0),
      inset -0.5px -1px 1.7px 0px rgb(70, 1, 1, 0.23),
      inset -1px -2px 3.4px 0px rgb(70, 1, 1, 0.46);
  }

  .tab-selected {
    background: white;
    box-shadow: none;
    z-index: 2;
  }

  .tab-selected.phones {
    border-radius: 10px 8px 0px 0px;
  }

  .tab-unselected.form {
    border-radius: 0px 10px 0px 8px;
  }

  .contact-main-card-content {
    height: 521px;
  }

  .social-links-card-content.mobile h1 {
    font-family: "Questrial";
    color: #a32529;
    font-weight: 700;
    text-align: center;
    height: 37px;
    margin-bottom: 20px;
    font-size: 25px;
  }

  .tab-unselected p {
    color: white;
    font-family: "Questrial";
    font-size: 20px;
    line-height: 15px;
    text-align: center;
  }

  .tab-selected p {
    color: #403d48;
    font-family: "Questrial";
    font-size: 20px;
    line-height: 15px;
    font-weight: 600;
    text-align: center;
  }

  .location-globe.mobile {
    font-size: 10px;
  }

  .location-globe-selected.mobile {
    font-size: 10px;
  }

  .location-numbers {
    margin-top: 37px;
    height: 192px;
  }

  .social-links-card.mobile {
    height: 313px;
  }

  .field {
    width: 100%;
  }

  .field.text-box {
    width: 100%;
  }

  svg.media-link {
    height: 24.66px;
    width: auto;
    margin-right: 9.66px;
  }

  .form-send-button {
    display: none;
  }

  button.form-send-button.mobile {
    width: 75px;
    height: 75px;
    display: flex;
    border-radius: 50%;
    /* background-color: #a32529; */
    background: linear-gradient(
      165deg,
      rgba(120, 20, 21, 0.7) 2.35%,
      rgba(120, 20, 21, 0.85) 34.9%,
      rgba(120, 20, 21, 0.95) 69.49%,
      rgba(120, 20, 21, 1) 100%
    );
    border: none;
    box-shadow: 0.3px 0.5px 0.7px rgb(161 161 161 / 34%),
      1.5px 2.9px 3.7px -0.4px rgb(161 161 161 / 34%),
      2.7px 5.4px 6.8px -0.7px rgb(161 161 161 / 34%),
      4.5px 8.9px 11.2px -1.1px rgb(161 161 161 / 34%),
      7.1px 14.3px 18px -1.4px rgb(161 161 161 / 34%),
      11.2px 22.3px 28.1px -1.8px rgb(161 161 161 / 34%);
    z-index: 4;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    margin-top: 40px;
    margin-right: 4px;
  }

  button.form-send-button.mobile svg {
    width: 33.5px;
    height: 33.5px;
    margin-top: 0.8px;
    margin-left: -1px;
  }

  .field .alert {
    color: #d2373d;
    font-family: "Oxygen";
    font-weight: 600;
    font-size: 9px;
    font-style: italic;
    margin-top: 0.8px;
    position: absolute;
    width: 145.5px;
    line-height: 7.5px;
  }

  .slider-buttons-container {
    margin-top: -208.93px;
    width: 100%;
    position: absolute;
    opacity: 0.25;
    transition: opacity 300ms ease-in-out;
    display: flex;
    justify-content: space-between;
  }

  .slider-buttons-container:hover {
    opacity: 1;
  }

  svg.scroll-to-right {
    width: 45px;
    height: 45px;
    /* position: absolute; */
    right: 0;
    padding-right: 1%;
  }

  svg.scroll-to-left {
    /* position: absolute; */
    left: 0;
    width: 45px;
    height: 45px;
    padding-left: 1%;
  }
}

@media screen and (min-width: 480px) {
  /* .mobile-nav {
    padding-right: 0px;
  } */

  .services-grid {
    padding-left: 41.5px;
  }

  .service-card img {
    width: 392px;
    height: 242px;
  }

  .hero-text h1 {
    font-size: 40px;
    padding-top: 6.75%;
  }

  .hero-text h2 {
    font-size: 15.5px;
  }

  .main-header {
    height: 8rem;
  }

  .about-container-text h2 {
    font-size: 2.7rem;
    line-height: 3.3rem;
  }

  .about-container-text h3 {
    font-size: 1.8rem;
    line-height: 2rem;
  }

  .capabilities-container-main {
    padding: 5%;
    padding-bottom: 30%;
  }

  .patente-card {
    width: 340px;
  }

  .locations-container {
    height: 400px;
  }

  .services-container-mask {
    background-position: 100% -20.5%;
  }

  .card-text {
    width: 61%;
  }

  .hide-nav-modal {
    display: block;
  }

  .modal-mobile-services-container {
    margin-top: -14%;
    position: static;
    height: auto;
    right: auto;
    width: auto;
    top: auto;
    z-index: auto;
  }

  .mobile-services-overlay-effect {
    display: none;
  }

  .mobile-modal-services-grid-container {
    margin-top: 0;
  }
  .modal-mobile {
    background: none;
    padding: 0px 0.3% 0px 0.3%;
    margin: 0;
    border: none;
    border-radius: 0px;
    z-index: auto;
    width: 87.75vw;
    position: static;
    transform: none;
  }

  .modal-mobile img {
    border: none;
    border-radius: 0;
  }

  .modal-mobile-text {
    background: white;
    border-radius: 6px;
    border: none;
    box-shadow: none;
  }
  .modal-mobile-link {
    display: flex;
  }
}

/* ////TABLET////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

@media screen and (min-width: 700px) {
  .modal-mobile-text-content {
    display: none;
  }

  .mobile-modal-card-shortcuts {
    display: none;
  }

  .modal-mobile-text h1 {
    text-align: left;
    text-transform: none;
    font-size: 1.17em;
  }

  .mobile-modal-icons {
    display: none;
  }
  .modal-mobile-active {
    transition: none;
    transform: none;
    z-index: 3;
    border: none;
    background: none;
    border-radius: 0;
  }

  .modal-mobile-text-inactive {
    display: block;
  }
  /* //// /////////////////////////////////////////////////////// */
  ::-webkit-scrollbar-thumb {
    /* background: rgba(31, 31, 31, 0.98); */
    background: transparent;
    border-radius: 2px;
    width: auto;
    display: block;
  }

  ::-webkit-scrollbar {
    width: 9px;
    /* background: rgba(64, 61, 72); */
    background: transparent;
    display: block;
  }

  ::-webkit-scrollbar:hover {
    background: rgba(64, 61, 72, 0.25);
    width: auto;
    display: block;
  }

  ::-webkit-scrollbar:hover:active {
    background: rgba(62, 59, 68, 0.5);
    width: auto;
    display: block;
  }

  .social-links {
    border-top: 0px;
  }

  .main-header {
    padding-left: 3%;
    padding-top: 2%;
  }

  .logo {
    width: 18.75rem;
    height: 14.4375rem;
  }

  .logo img {
    margin-top: 0px;
    margin-left: 0px;
  }

  .hero-content {
    background-size: cover;
    background-position: 79% center;
    /* height: 68vh; */
  }

  /* .mobile-nav {
    margin-top: -0.5%;
    margin-left: 38%;
  } */

  svg.mobile-nav-bars {
    width: 40px;
    height: 30px;
  }

  .mobile-nav-circle {
    width: 58px;
    height: 58px;
    top: 16px;
    right: 21px;
    box-shadow: 6px 7px 5px rgb(0 0 0 / 20%);
  }

  .hero-text h1 {
    font-size: 79px;
    letter-spacing: -2px;
    line-height: 86.9px;
    padding-top: 12%;
    margin-bottom: 2.3%;
  }

  .hero-text h2 {
    padding: 0px 9% 0px 9%;
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 27px;
  }

  .about-container-main {
    height: 204vh;
    padding-top: 7%;
    padding-left: 10%;
    padding-right: 0px;

    clip-path: polygon(50% 0px, 100% 0px, 100% 75%, 100% 75%, 0 95%, 0px 0px);
  }

  .about-container-content {
    width: 84%;
  }

  .about-container-text h2 {
    font-size: 3.75rem;
    line-height: 4.125rem;
  }

  .about-container-text h3 {
    font-size: 2.8125rem;
    line-height: 3.09375rem;
  }

  .about-icon-list li {
    display: flex;
  }

  .aceves-h1 {
    margin-bottom: 7.25%;
  }

  /* .about-h1 {
    padding-right: 10%;
  } */

  .aceves-h1 h1 {
    font-size: 4.5rem;
    letter-spacing: 0px;
    line-height: 86.9px;
    font-weight: 500;
    margin-bottom: 12px;
  }

  svg.aceves-title-line {
    width: 290px;
  }

  .capabilities-container-main {
    padding: 10%;
    flex-direction: column;
    margin-top: -40%;
    padding-bottom: 32.75%;
  }

  /* .capabilities-container-text {
    width: 64ch;
  } */

  .locations-container {
    width: 100%;
    height: 520px;
    border: 20px solid #fafafa;
  }

  .capabilities-container-right {
    padding-left: 0px;
    margin-top: 0px;
    margin-top: 16%;
  }

  .capabilities-icon-list li {
    display: flex;
  }

  .services-container {
    margin-top: -10%;
    margin-top: -12.75%;
  }

  .services-container-mask {
    background-position: 101% -12.25%;
  }

  .services-main-container {
    position: static;
    height: 100%;
    overflow: visible;
    padding-bottom: 10%;
    padding-top: 12.5%;
  }

  .services-h1 {
    /* padding-left: 10%; */
    margin-top: 12%;
    margin-bottom: 8%;
    position: static;
  }

  .services-grid {
    display: grid;
    grid-template-columns: 27% 27% 27%;
    column-gap: 4%;
    padding: 0px;
    padding-left: 3%;
    padding-right: 3%;
    row-gap: 204px;
    margin-bottom: 25%;
    inset: auto;
    width: auto;
    margin-top: 0px;
  }

  .service-card {
    height: 198px;
    opacity: 1;
    visibility: visible;
    position: static;
    padding: 0px;
    width: 100%;
  }

  .service-card img {
    margin-top: -12%;
    width: 100%;
    height: 168px;
  }

  .service-card.mob {
    display: none;
  }

  .services-button-div {
    display: flex;
  }

  .card-text {
    padding: 8px 6px 22.8px 11px;
    font-weight: 700;
    font-size: 12px;
    line-height: 17.5px;
    margin-top: -16%;
    width: 88%;
  }

  .card-text h1 {
    max-height: 34px;
  }

  .card-text p {
    font-size: 14px;
  }

  .card-text:hover {
    transform: scale(1.085);
    transition: all 200ms ease-in-out;
    /* box-shadow: 0 4px 8px rgb(0 0 0 / 18%); */
    box-shadow: 0.3px 0.5px 0.7px rgb(21 10 10 / 34%),
      1.5px 2.9px 3.7px -0.4px rgb(21 10 10 / 34%),
      2.7px 5.4px 6.8px -0.7px rgb(21 10 10 / 34%),
      4.5px 8.9px 11.2px -1.1px rgb(21 10 10 / 34%),
      7.1px 14.3px 18px -1.4px rgb(21 10 10 / 34%),
      11.2px 22.3px 28.1px -1.8px rgb(21 10 10 / 34%);
    transition-delay: 200ms;
  }

  .card-link-icon {
    margin-top: 9%;
  }

  .services-modal-overlay {
    display: block;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    right: 0;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    /* transition: width 0.65s, height 0.65s; */
  }

  .modal-box-container {
    background-color: white;
    color: black;
    overflow: hidden;
    box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  }

  .modal-box-container-content {
    width: 100%;
    height: 100%;
    display: flex;
  }

  .modal-picture-container {
    width: 48%;
    height: 100%;
    position: relative;
  }

  .modal-picture-filter {
    width: 100%;
    height: 100%;
    background: linear-gradient(
      318deg,
      rgba(8, 12, 12, 0.32) -7.64%,
      rgba(7, 10, 34, 0.42) 30.19%,
      rgba(3, 5, 17, 0.52) 72.21%
    );
    position: absolute;
  }

  .modal-picture-filter h2 {
    font-family: "Oxygen";
    font-weight: 700;
    font-size: 44px;
    line-height: 58px;
    padding: 50px 30px;
    /* or 132% */

    color: #ffffff;
    text-shadow: 0px 3.43977px 3.43977px rgba(0, 0, 0, 0.45);
  }

  .modal-picture {
    height: 100%;
  }

  .modal-picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }

  .modal-service-icon {
    width: 16%;
    height: 9%;
    background-color: #a32529;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 1.78px 2.5px rgba(0, 0, 0, 0.18);
  }

  .modal-service-icon svg {
    width: 36px;
    height: 36px;
  }

  .modal-service-icon img {
    width: 36px;
    height: 36px;
  }

  /* .services-modal-active {
    width: 0;
    height: 0;
  }
  
  .services-modal-inactive {
    width: 100%;
    height: 100vh;
  } */

  .modal-text-container {
    width: 52%;
    height: 100%;
  }

  .modal-text {
    height: 100%;
  }

  .modal-service-text {
    /* padding: 0 30px;
    padding-top: calc(9% + 66px); */
  }

  .modal-service-text p {
    font-size: 20px;
    line-height: 27px;
    font-family: "Oxygen";
    font-weight: 400;
    text-align: justify;
    color: #403d48;
    padding: 0 30px;
    /* padding-top: calc(9% + 36px); */
    padding-top: calc(12% + 50px);
    padding-right: calc(54px + 3.5%);
  }

  .modal-close-icon {
    position: relative;
  }

  .modal-close-icon svg {
    position: absolute;
    right: 0;
    cursor: pointer;
    height: 24px;
    width: 24px;
    margin-right: 3.5%;
    margin-top: 3.5%;
  }

  .modal-service-button {
    margin: 5% 30px 16% 30px;
    background: #a32529;
    border-radius: 6px;
    border: transparent 0px;
    font-family: "Helvetica";
    padding: 3% 5.5%;
    padding-top: 3.25%;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 1.8px;
    cursor: pointer;
    color: #ffffff;
  }

  .footer-logo-shortcuts.modal {
    justify-content: center;
  }

  .modal-service-iconlinks {
    padding: 0 30px;
    padding-right: calc(54px + 3.5%);
  }

  .footer-shortcut {
    width: 2rem;
    height: 2rem;
  }

  .footer-shortcut.modal {
    margin-left: 1.5rem;
    width: 2.5rem;
    height: 2.5rem;
  }

  /* .carousel-button {
    display: none;
  } */

  .patentes-container {
    height: 106.85vh;
    background-position: 0% -6.65%;
  }

  .patentes-main-container {
    padding-top: 16%;
    padding-bottom: 10%;
    overflow-x: visible;
    overflow-y: visible;
  }

  /* h1.mobile {
    display: none;
  }

  h1.desktop {
    display: block;
  } */

  .patentes-grid {
    padding-left: 1%;
    padding-right: 3%;
    grid-template-columns: 40% 40%;
    column-gap: 6%;
    row-gap: 24vh;
    display: grid;
    width: auto;
    align-items: normal;
    margin-bottom: 25%;
  }

  .patente-card {
    height: 28vh;
    width: 40vw;
    margin: 0;
    padding: 0 5% 0 5%;
  }

  .patente-card-header {
    margin-top: -3.5%;
    margin-left: -3.5%;
    margin-bottom: -7.5%;
    padding-left: 3%;
    padding-right: 3%;
  }

  .patente-card-header img {
    width: 35%;
    height: 88px;
  }

  .patente-card-header p {
    font-size: 1.1rem;
  }

  .patente-card-content {
    padding-top: 2.5%;
    padding-bottom: 0;
    height: 70%;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: normal;
  }

  .patente-card-content h2 {
    font-size: 4.75rem;
    margin-bottom: 2.5%;
  }

  .patente-card-content h3 {
    font-size: 1.75rem;
  }

  .patentes-h1 {
    margin-top: 13.75%;
    margin-bottom: 7.5%;
  }

  .contact-container-main {
    padding-top: 14%;
    padding-bottom: 14%;
    margin-top: 0;
  }

  .contact-main-card {
    width: 660px;
  }

  /* .contact-main-card-title svg {
    margin-bottom: 0;
  } */
  /* .contact-main-card-title h2 {
    text-align: left;
    color: #a32529;
    font-size: 37.5px;
    line-height: 38px;
    font-weight: 400;
    text-transform: none;
  } */
  /* .contact-main-card-mobile-header {
    display: none;
    overflow: visible;
  } */

  .contact-main-card-content {
    height: 100%;
    height: 822px;
  }

  .social-links-card {
    background: #fafafa;
    width: 25vw;
    box-shadow: 0.3px 0.5px 0.7px rgb(158 158 158 / 34%),
      1.5px 2.9px 3.7px -0.4px rgb(158 158 158 / 34%),
      2.7px 5.4px 6.8px -0.7px rgb(158 158 158 / 34%),
      4.5px 8.9px 11.2px -1.1px rgb(158 158 158 / 34%),
      7.1px 14.3px 18px -1.4px rgb(158 158 158 / 34%),
      11.2px 22.3px 28.1px -1.8px rgb(158 158 158 / 34%);
    margin-top: 14%;
    margin-left: -22%;
    padding: 38.5px 12.5px 38.5px 12.5px;
    overflow: hidden;
    /* display: block; */
  }

  .social-links-card-content {
    margin-bottom: 6%;
  }

  .social-links-card-content h1 {
    font-family: "Questrial";
    color: #a32529;
    font-size: 28px;
    line-height: 31px;
    font-weight: 500;
  }

  .input-box {
    padding: 0.1875rem 0.7rem;
    height: 46%;
    /* margin: 1.6%; */
    margin-top: 0.3%;
    margin-left: 0.05rem;

    border: 0.65px solid rgba(0, 0, 0, 0.1);
  }

  .input-box .input {
    height: 4.3vh;
    font-size: 16px;
  }

  .name-boxes p {
    margin-bottom: 2px;
    line-height: 25.6px;
    font-size: 16px;
  }

  .input-box.textarea-box {
    padding-top: 0.6rem;
    height: 176px;
  }

  .input.text-area {
    /* width: 47vw; */
    line-height: 1.5rem;
    height: 100%;
  }

  /* .socialmedia-links.mobile {
    display: none;
  } */

  .location-numbers {
    margin-top: 12%;
    height: 340px;
  }

  button.form-send-button svg {
    margin-top: 0.5px;
    margin-left: -3px;
  }

  button.form-send-button.mobile {
    width: 110px;
    height: 110px;
    /* margin-top: 46px; */
    margin-top: 6%;
    margin-right: 42px;
  }

  .field .alert {
    font-size: 14px;
    margin-top: -0.95rem;
    width: auto;
    line-height: normal;
  }

  .field.first-box {
    margin-right: 24px;
  }

  svg.media-link {
    height: 36.66px;
  }

  .contact-main-card-content-current {
    padding: 38px;
  }

  .socialmedia-links {
    margin-bottom: 36px;
  }

  button.form-send-button.mobile svg {
    width: 45.5px;
    height: 45.5px;
  }

  .contact-main-card-container-section {
    margin-top: 25%;
  }

  .location-globe {
    width: 120px;
    height: 34px;
    font-size: 14px;
  }

  .location-globe.mobile {
    font-size: 14px;
  }

  .location-globe-selected {
    width: 120px;
    height: 34px;
    font-size: 14px;
  }

  .location-globe-selected.mobile {
    font-size: 14px;
  }

  .phonenumber-digits p {
    font-size: 28px;
  }

  .slider-buttons-container {
    display: none;
  }
}

/* ////LAPTOP////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (min-width: 960px) {
  /* .mobile-nav-container {
    display: none;
  } */

  .social-links {
    padding-top: 0px;
    padding-right: 1rem;
  }

  .main-header {
    padding-left: 5%;
  }

  .mobile-nav {
    display: none;
  }

  .hero-content {
    /* height: 119vh; */
    background-size: cover;
    background-position: center center;
  }

  .hero-text {
    padding: 2.5% 14% 0px 14%;
  }

  .hero-text h1 {
    padding-top: 9%;
    margin-bottom: 3.25%;
  }

  .hero-text h2 {
    /* margin-bottom: 0px; */
    padding: 0px 8.5% 0px 8.5%;
    font-size: 1.125rem;
    line-height: 1;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 27px;
  }

  .main-nav {
    overflow-x: visible;
  }

  /* .main-nav menu {
    display: flex;
  } */

  .about-container-main {
    padding-bottom: 56%;
    padding-top: 7%;
    padding-left: 10%;
    padding-right: 10%;
  }

  /* .about-h1 {
    padding-right: 10%;
  } */

  .capabilities-container-main {
    padding-bottom: 24%;
    margin-top: -16%;
  }

  /* .capabilities-container-text {
    width: 57ch;
  } */

  .capabilities-container-right {
    margin-top: 15%;
    padding-left: 6%;
  }

  .locations-container {
    height: 680px;
    margin-top: -44%;
  }

  .services-container {
    margin-top: -12%;
  }

  .services-container-mask {
    background-position: 100% -6.25%;
  }

  .services-grid {
    row-gap: 30vh;
    grid-template-columns: 22% 22% 22%;
    column-gap: 7%;
    padding-left: 3.5%;
    padding-right: 3.5%;
  }

  .service-card {
    height: 223.675px;
  }

  .service-card img {
    /* width: 393px; */
  }

  .carousel-button {
    display: none;
  }

  /* .service-card.mob {
    display: none;
  } */

  .card-text {
    width: 85%;
    font-size: 12px;
    padding: 20.6px 9.5px 22.8px 14.23px;
    font-weight: 700;
    line-height: 17px;
    margin-top: -16%;
  }

  .patente-card {
    width: 37vw;
    height: 37vh;
  }

  .patentes-container {
    height: 144vh;
    background-position: 0% 0.5%;
  }

  .patentes-grid {
    padding-left: 1.75%;
    padding-right: 1.75%;
    grid-template-columns: 40% 40%;
    column-gap: 2.75%;
    row-gap: 32vh;
  }
  .patente-card-header img {
    height: 98px;
  }

  .patentes-h1 {
    margin-top: 11.5%;
  }

  h1.mobile {
    display: none;
  }

  h1.desktop {
    display: block;
  }

  .contact-container-main {
    padding-top: 20%;
    padding-bottom: 20%;
  }

  .contact-main-card {
    width: 90vw;
  }

  .contact-main-card-container {
    margin-left: 8%;
  }

  .contact-main-card-content {
    padding: 20px;
    padding-top: 0;
    height: auto;
    width: 100%;
    padding: 9% 0 4.82% 5.57%;
  }

  .contact-main-card {
    padding: 0 5% 0 5%;
    flex-direction: row;
    width: 67vw;
    overflow: visible;
  }

  .contact-main-card-title h2 {
    text-align: left;
    color: #a32529;
    font-size: 37.5px;
    line-height: 38px;
    font-weight: 400;
    text-transform: none;
  }

  .social-links-card {
    display: block;
    margin-top: 7.5%;
  }

  /* .contact-container {
    height: 206vh;
  } */

  .contact-main-card-mobile-header {
    display: none;
    overflow: visible;
  }

  .contact-main-card-content-current {
    padding: 0;
  }

  .contact-main-card-title {
    display: flex;
    align-items: flex-start;
    padding-right: 2.5%;
    flex-direction: row;
    margin-bottom: 3%;
  }

  .contact-h1 {
    margin-right: 0px;
    margin-top: 76px;
    display: block;
  }

  .contact-main-card-title svg {
    margin-bottom: 0;
  }

  .input-boxes {
    margin-bottom: 3%;
  }

  .name-boxes {
    margin-bottom: 1%;
  }

  .name-boxes p {
    margin-bottom: 7px;
  }

  .field {
    width: auto;
  }

  .input-box {
    padding: 0.1875rem 0.7rem;
    /* height: 46%; */
    margin: 1.6%;
    margin-top: 0.3%;
    margin-left: 0.05rem;
    margin-bottom: 1.25rem;
    background-color: #eeedef;
    border: 0.65px solid rgba(0, 0, 0, 0.1);
  }

  /* .field.first-box {
    margin-right: 24px;
} */

  .socialmedia-links.mobile {
    display: none;
  }

  button.form-send-button.mobile {
    display: none;
  }

  button.form-send-button {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-color: #a32529;
    border: none;
    box-shadow: 0.3px 0.5px 0.7px rgb(161 161 161 / 34%),
      1.5px 2.9px 3.7px -0.4px rgb(161 161 161 / 34%),
      2.7px 5.4px 6.8px -0.7px rgb(161 161 161 / 34%),
      4.5px 8.9px 11.2px -1.1px rgb(161 161 161 / 34%),
      7.1px 14.3px 18px -1.4px rgb(161 161 161 / 34%),
      11.2px 22.3px 28.1px -1.8px rgb(161 161 161 / 34%);
    z-index: 4;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin-left: 45.6%;
    margin-top: -6.25%;
  }

  .field.text-box {
    width: auto;
  }

  .input-box .input {
    width: 21vw;
  }

  .input.text-area {
    width: 47vw;
  }

  .socialmedia-links {
    margin-top: 4%;
    margin-bottom: 0;
  }

  button.form-send-button.mobile {
    width: 75px;
    height: 75px;
  }

  button.form-send-button.mobile svg {
    width: 33.5px;
    height: 33.5px;
  }

  .contact-main-card-container-section {
    margin-top: 0;
  }

  .location-globe-selected {
    width: 64px;
    height: 26px;
    font-size: 12px;
  }

  .location-globe {
    width: 64px;
    height: 26px;
    font-size: 12px;
  }

  .location-globe.mobile {
    font-size: 12px;
  }

  .location-globe-selected.mobile {
    font-size: 12px;
  }

  .location-numbers {
    height: 128px;
  }

  .phonenumber-digits p {
    font-size: 20px;
  }

  .modal-box-container {
    margin-top: -0.65%;
    width: 72.5%;
    height: 90vh;
    border-radius: 22px;
  }
}

/* /Ipad Pro////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

@media screen and (min-width: 1200px) {
  .hero-content {
    height: 119vh;
  }

  .main-background-container img {
    height: 119vh;
  }

  .main-header {
    position: absolute;
  }

  .hero-text {
    margin-top: -15%;
  }

  .hero-text h2 {
    margin-bottom: 0px;
  }

  .about-container-main {
    height: 310.3vh;
    padding-bottom: 40%;
  }

  .about-container-content {
    width: 56%;
  }

  .capabilities-container-main {
    padding-bottom: 20%;
    flex-direction: row;
  }

  .capabilities-container-right {
    margin-top: -5%;
  }

  .locations-container {
    width: 528px;
    height: 480px;
  }

  .services-grid {
    row-gap: 372.8px;
  }

  .services-container {
    margin-top: -9%;
  }

  .service-card {
    height: 325px;
  }

  .service-card img {
    height: 214px;
  }

  .card-text {
    width: 82%;
    line-height: 21px;
    font-size: 15px;
  }

  .patentes-container {
    margin-top: -9%;
    height: 216vh;
  }

  .patentes-grid {
    row-gap: 59vh;
  }

  .patente-card {
    height: 60vh;
  }
  .patente-card-header img {
    height: 132px;
  }

  .input-box.textarea-box {
    height: 28vh;
  }

  .services-container-mask {
    background-position: 100% -2%;
  }

  .main-nav menu {
    display: flex;
  }

  .mobile-nav-container {
    display: none;
  }

  .card-text h1 {
    max-height: 41.672px;
  }
}

/* /Macbook Air////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

@media screen and (min-width: 1426px) {
  .about-container-main {
    height: 264.8vh;
  }

  .locations-container {
    margin-top: -34%;
  }
}

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (min-width: 1640px) {
  .hero-text {
    padding: 5.75% 24% 0px 24%;
    margin-top: -17%;
  }

  .capabilities-container-text {
    width: 85ch;
  }

  .social-links {
    padding-right: 10%;
  }

  .main-header {
    padding-left: 10%;
  }

  .hero-content {
    height: 113vh;
  }

  img.hero-image-1x {
    display: none;
  }

  img.hero-image-full {
    display: block;
  }

  .main-background-container img {
    height: 113vh;
  }

  /* .hero-text h2 {
    color: #3c374b;
  } */
  .aceves-h1 {
    margin-bottom: 4.5%;
  }

  .aceves-h1 h1 {
    font-size: 75px;
    margin-bottom: 6px;
  }

  .hero-text h1 {
    padding-top: 12%;
    margin-bottom: 4.45%;
  }

  .hero-text h2 {
    color: #cac8d0;
  }

  .about-container-main {
    height: 259.5vh;
    background-size: 47vw 41vw;
    padding-top: 5%;
  }

  .about-container-text p {
    width: 70%;
  }

  .about-container-text h2 {
    width: 55%;
  }

  .main-nav {
    margin-right: 12%;
  }

  .locations-container {
    width: 709px;
    height: 547.42px;
  }

  .location-numbers {
    height: 188px;
  }

  .location-numbers-globe {
    width: 86%;
  }

  .location-globe {
    width: 78px;
  }

  .location-globe-selected {
    width: 78px;
  }

  .services-grid {
    row-gap: 480.5px;
  }

  .services-main-container {
    padding-top: 10.5%;
  }

  .services-container-mask {
    background-position: 100% -1.25%;
  }

  .services-h1 {
    margin-bottom: 5.5%;
    margin-top: 10%;
  }

  .services-button-div {
    height: 22%;
    margin-bottom: 5%;
  }

  .services-button {
    width: 12.5rem;
    height: 3.7rem;
    font-size: 15.85px;
    line-height: 21px;
    letter-spacing: 1.8px;
    font-weight: 400;
  }

  .service-card {
    height: 432.33px;
  }

  .service-card img {
    height: 292px;
  }

  .modal-service-text p {
    font-size: 23px;
    line-height: 30px;
  }

  .card-text {
    height: 44.8%;
    padding: 26.6px 9.5px 22.8px 15.23px;
    font-size: 21px;
    line-height: 28px;
    font-weight: bolder;
  }

  .card-text h1 {
    max-height: 45%;
  }

  .card-text p {
    font-weight: 700;
    font-size: 19px;
    line-height: 31px;
    text-decoration-line: underline;
  }

  .card-link-icon {
    margin-top: 10%;
  }

  .card-text svg {
    margin-top: -3%;
    width: 2.85rem;
    height: 2.85rem;
  }

  .capabilities-container-right {
    padding-top: 2%;

    padding-left: 3%;
  }

  .patentes-container {
    height: 202.5vh;
    background-position: 0% 0.5%;
  }

  .patente-card {
    height: 60vh;
    width: 37vw;
  }

  .patentes-h1 {
    margin-top: 10%;
    height: 21vh;
    margin-bottom: 7.5%;
  }

  .patente-card-header img {
    height: 164px;
  }

  .patente-card-content h2 {
    font-size: 6.2rem;
    font-weight: 600;
    margin-bottom: 2.5%;
  }

  .patente-card-content h3 {
    font-size: 2.5rem;
    font-weight: 500;
  }

  .contact-container-main {
    padding-top: 13.5%;
    padding-bottom: 13.5%;
  }

  .contact-main-card-title h2 {
    font-size: 51px;
    line-height: 52px;
  }

  .input-box .input {
    width: 22vw;
  }

  .name-boxes p {
    font-size: 18px;
    margin-bottom: 7.5px;
  }

  .name-boxes {
    margin-bottom: 1.5%;
  }

  .input.text-area {
    width: 48vw;
  }

  /* .contact-main-card {
    height: 76vh;
  } */

  .social-links-card {
    height: 64vh;
    margin-top: 9%;
  }

  .social-links-card-content h1 {
    font-size: 38px;
  }

  .socialmedia-links {
    margin-top: 10%;
  }

  .phonenumber-digits p {
    font-size: 24px;
    line-height: 20px;
  }

  .phonenumber {
    margin-bottom: 10%;
  }

  /*
  .contact-container {
    height: 172vh;
  } */

  button.form-send-button {
    width: 85px;
    height: 85px;
    margin-left: 46.75%;
    margin-top: -5%;
  }

  .footer-logo {
    width: 21.95rem;
  }

  .footer-column {
    margin-right: 17%;
  }

  .footer-logo img {
    margin-bottom: 4rem;
  }

  .footer-last-column {
    margin-right: 10.3%;
  }

  .footer-column.first {
    margin-right: 7.3%;
  }

  .footer-columns li {
    font-size: 20px;
    margin-bottom: 20.5px;
  }

  .footer-logo-links li {
    font-size: 12px;
    margin-bottom: 2px;
  }

  .footer-columns {
    padding: 7.75% 9% 6% 9%;
  }

  .modal-box-container {
    margin-top: 1.25%;
    width: 72.5%;
    height: 82vh;
    border-radius: 18px;
  }

  .card-text h1 {
    cursor: pointer;
    max-height: 45%;
  }
}
